import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO
      data={{
        title: `404: Not found`,
      }}
    />
    <div
      className="container has-text-centered"
      style={{ padding: `24rem 0 0` }}
    >
      <h1>404</h1>
      <p>The page requested was not found.</p>
    </div>
  </div>
)

export default NotFoundPage
